import { HttpClient, HttpParams } from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {Observable} from "rxjs";
import {ListTechnicianModel} from "./models/technician/list-technician.model";
import {Injectable} from "@angular/core";
import {CreateUpdateTechnicianModel} from "./models/technician/create-update-technician.model";
import {GetTechnicianModel} from "./models/technician/get-technician.model";
import {DropdownTechnicianModel} from "./models/technician/dropdown-technician.model";

@Injectable({
  providedIn: 'root'
})
export class TechnicianService {
  constructor(private http: HttpClient) {
  }

  getListTechnician(technicianId: number): Observable<ListTechnicianModel> {
    return this.http.get<ListTechnicianModel>(`${environment.technicianApiBaseUrl}/technician/list/${technicianId}`);
  }

  getListTechnicians(page: number, countPerPage: number, query?: string | null, vendorIds?: number[] | null, competencyIds?: number[] | null): Observable<{
    results: ListTechnicianModel[],
    count: number
  }> {
    let params = new HttpParams()
      .set('page', page.toString())
      .set('countPerPage', countPerPage.toString());

    if (query != null) {
      params = params.set('query', query);
    }

    if (vendorIds != null) {
      vendorIds.forEach(id => {
        params = params.append('vendorIds', id.toString());
      });
    }

    if (competencyIds != null) {
      competencyIds.forEach(id => {
        params = params.append('competencyIds', id.toString());
      });
    }

    return this.http.get<{
      results: ListTechnicianModel[],
      count: number
    }>(`${environment.technicianApiBaseUrl}/technician/list`, {
      params,
    });
  }

  getTechnician(technicianId: number): Observable<GetTechnicianModel> {
    return this.http.get<GetTechnicianModel>(`${environment.technicianApiBaseUrl}/technician/${technicianId}`);
  }

  createTechnician(technician: CreateUpdateTechnicianModel): Observable<GetTechnicianModel> {
    return this.http.post<GetTechnicianModel>(`${environment.technicianApiBaseUrl}/technician`, technician);
  }

  updateTechnician(technicianId: number, technician: CreateUpdateTechnicianModel): Observable<GetTechnicianModel> {
    return this.http.put<GetTechnicianModel>(`${environment.technicianApiBaseUrl}/technician/${technicianId}`, technician);
  }

  getListTechniciansWhereIn(ids: number[]): Observable<ListTechnicianModel[]> {
    let httpParams = new HttpParams();
    ids.forEach(id => {
      httpParams = httpParams.append('ids', id.toString());
    });

    return this.http.get<ListTechnicianModel[]>(`${environment.technicianApiBaseUrl}/technician/list/ids`, {
      params: httpParams
    });
  }

  getDropdownTechnicians(vendorId?: number): Observable<DropdownTechnicianModel[]> {
    let httpParams = new HttpParams();
    if (vendorId != null) {
      httpParams = httpParams.set('vendorId', vendorId.toString());
    }

    return this.http.get<DropdownTechnicianModel[]>(`${environment.technicianApiBaseUrl}/technician/dropdown`, {
      params: httpParams
    });
  }
}
